import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Servizi from './pages/Servizi.js'
import Chisiamo from './pages/Chisiamo.js'
import Policy from './modules/Policy.js'
import App from "./pages/App.js"
import Contatti from "./pages/Contatti.js"
import Footer from "./modules/Footer.js"
import Navbar from "./modules/Navbar"

const root = ReactDOM.createRoot(document.getElementById('root'));
window.langchanged = false
window.settingsChanged = false
window.settingsChanged = false
window.hideContactUs = false

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Navbar></Navbar>
      <Routes>
        <Route path="/" element={ <App /> } />
        <Route path="/servizi" element={ <Servizi /> } />
        <Route path="/azienda" element={ <Chisiamo /> } />
        <Route path="/contatti" element={ <Contatti /> } />
        <Route path="/policy" element={ <Policy /> } />
      </Routes>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
)

