import React from 'react'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'

export default function CookiesBanner(props) {
    const [language, setLanguage] = useState('')
    const [accept, setAccept] = useState('')
    const [decline, setDecline] = useState('')

    let navigate = useNavigate()

    const agree = () => {
        localStorage.setItem("cookieAccepted", "true")
        props.setTrigger(false)
    }

    const disagree = () => {
        localStorage.removeItem("cookieAccepted")
        props.setTrigger(false)
    }

    const setLang = () => {
        let lang = localStorage.getItem("language")
        let dictionary = JSON.parse(localStorage.getItem("dictionary"))
        if (lang === undefined || lang === null || lang === '' || lang == "ita") {
            setLanguage("ita")
            setAccept(dictionary.acceptButton.it)
            setDecline(dictionary.declineButton.it)
        }
        else if (lang == "eng") {
            setLanguage("eng")
            setAccept(dictionary.acceptButton.en)
            setDecline(dictionary.declineButton.en)
        }
        else if (lang == "fra") {
            setLanguage("fra")
            setAccept(dictionary.acceptButton.fr)
            setDecline(dictionary.declineButton.fr)
        }
    }

    useEffect(() => {
        setLang()
        window.langchanged = false
    }, [window.langchanged])

    useEffect(() => {
        setLang()
    }, [])

    return (props.trigger) ? (
        <div className='cookie'>
            <div className='cookieFrame'>
                <div style={{ display: (language === "ita") ? 'block' : 'none' }} className='cookieModalHeader'>
                    <h4>Cookie Policy</h4>
                </div>
                <div style={{ display: (language === "ita") ? 'block' : 'none' }} className='cookieModalBody'>
                    Questo sito web <b>non utilizza Cookies</b> che consentano di tracciare gli Utenti.<br></br>
                    Viene utilizzata la memoria locale del browser unicamente per salvare le preferenze su:<p></p>
                    - Linguaggio usato<br></br>
                    - Accettazione della Policy sulla Privacy<br></br>
                    - Accettazione della Policy sui Cookie<p></p>
                    <span style={{ fontSize: "12px" }}>Per maggiori informazioni visitate la <span onClick={() => {navigate("/policy")}}><span className='underlineText'>Privacy Policy</span></span></span>
                </div>

                <div style={{ display: (language === "eng") ? 'block' : 'none' }} className='cookieModalHeader'>
                    <h4>Cookie Policy</h4>
                </div>
                <div style={{ display: (language === "eng") ? 'block' : 'none' }} className='cookieModalBody'>
                This website <b>does not use Cookies</b> that allow tracking of Users.<br></br>
                The local memory of the browser is used only to save preferences on:<p></p>
                - Language used<br></br>
                - Acceptance of the Privacy Policy<br></br>
                - Acceptance of the Cookie Policy<p></p>
                <span style={{ fontSize: "12px" }}>For more information visit the <span onClick={() => {navigate("/policy")}}><span className='underlineText'>Privacy Policy</span></span></span>
                </div>

                <div style={{ display: (language === "fra") ? 'block' : 'none' }} className='cookieModalHeader'>
                    <h4>Cookie Policy</h4>
                </div>
                <div style={{ display: (language === "fra") ? 'block' : 'none' }} className='cookieModalBody'>
                Ce site Web <b>n'utilise pas de cookies</b> permettant le suivi des utilisateurs.<br></br>
                La mémoire locale du navigateur est utilisée uniquement pour enregistrer les préférences sur:<p></p>
                - Langue utilisée<br></br>
                - Acceptation de la politique de confidentialité<br></br>
                - Acceptation de la politique de cookies<p></p>
                <span style={{ fontSize: "12px" }}>Pour plus d'informations, visitez le <span onClick={() => {navigate("/policy")}}><span className='underlineText'>Privacy Policy</span></span></span>
                </div>

                <div className='cookieModalFooter'>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <button className={`${'btn'} ${'btn-outline-success'} ${'buttonCustomWidth'}`} onClick={agree}>{accept}</button>
                        </div>
                        <div className='col-sm-3'>
                            <button className={`${'btn'} ${'btn-outline-danger'} ${'buttonCustomWidth'}`} onClick={disagree}>{decline}</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    ) : ""
}