import '../App.css'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import CookiesBanner from "../modules/CookiesBanner"

export default function Footer(props) {
  const [sedeLegale, setSedeLegale] = useState('')
  const [trigger, setTrigger] = useState(false)
  const [contattaciButton, setContattaciButton] = useState('')
  const [contattaciFooter, setContattaciFooter] = useState('')
  
  let navigate = useNavigate()  

  const updateStrings = () => {
    let language = localStorage.getItem("language")
    let dictionary = JSON.parse(localStorage.getItem("dictionary"))
    if (language === "ita") {
      setSedeLegale(dictionary.sede_legale.it)
      setContattaciButton(dictionary.contattaci_button.it)
      setContattaciFooter(dictionary.contattaci_footer.it)
    }
    else if (language === "eng") {
      setSedeLegale(dictionary.sede_legale.en)
      setContattaciButton(dictionary.contattaci_button.en)
      setContattaciFooter(dictionary.contattaci_footer.en)
    }
    else if (language === "fra") {
      setSedeLegale(dictionary.sede_legale.fr)
      setContattaciButton(dictionary.contattaci_button.fr)
      setContattaciFooter(dictionary.contattaci_footer.fr)
    }
    else
      localStorage.setItem("language", "ita")
  }

  useEffect(() => {
    updateStrings()
  }, [window.langchanged])

  useEffect(() => {
    updateStrings()
  }, [])

return (
      <>
        <div className="appFooter">
          <div className='row pt-2'>
            <div className='col centerNoPad ps-4'>{sedeLegale}: Via dell'Artigianato, 4/B</div>          
            <div className='col centerNoPad'>P.IVA:05234470283</div>                    
            <div className='col centerNoPad underlineText' onClick={() =>navigate("/policy")}>Privacy Policy</div>
          </div>                 
          <div className='row pb-2'>
            <div className='col centerNoPad ps-4'>30020 Candiana PD</div>          
            <div className='col centerNoPad'>REA:453473</div>                    
            <div className='col centerNoPad underlineText' onClick={() =>setTrigger(true)}>Cookie Policy</div>
          </div>                 
          <div className='row arbDisclaimer border-top pb-1'>
            <div className='col centerNoPad underlineText'><a href='https://arbcontentmanager.com/'>Powered by ARbcontentmanager</a></div>          
          </div>                 
        </div>
        <CookiesBanner trigger={trigger} setTrigger={setTrigger}></CookiesBanner>
      </>
  )
}