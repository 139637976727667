import '../App.css'
import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet';

export default function Policy() {
    let navigate = useNavigate()
    const [language, setLanguage] = useState('')
    const [accept, setAccept] = useState('')
    const [decline, setDecline] = useState('')

    const agree = () => {
        localStorage.setItem("privacy", "yes")
        navigate('/')
    }
    const disagree = () => {
        localStorage.removeItem("privacy")
        navigate('/')
    }

    const setLang = () => {
        let lang = localStorage.getItem("language")
        let dictionary = JSON.parse(localStorage.getItem("dictionary"))
        if (lang === undefined || lang === null || lang === '' || lang === "ita") {
            setLanguage("ita")
            setAccept(dictionary.acceptButton.it)
            setDecline(dictionary.declineButton.it)
        }
        else if (lang === "eng") {
            setLanguage("eng")
            setAccept(dictionary.acceptButton.en)
            setDecline(dictionary.declineButton.en)
        }
        else if (lang === "fra") {
            setLanguage("fra")
            setAccept(dictionary.acceptButton.fr)
            setDecline(dictionary.declineButton.fr)
        }
    }

    useEffect(() => {
        setLang()
        window.langchanged = false
    }, [window.langchanged])

    useEffect(() => {
        setLang()
    }, [])

    return (
        <>
         <Helmet>
            <title>ZC Fashion - Privacy Policy</title>
            <meta name="description" content="" />
        </Helmet>
        <h4 style={{ display: (language === "ita") ? 'block' : 'none' }} className="titleLeft">Privacy Policy di https://www.zcfashion.org/</h4>
        <h6 style={{ display: (language === "ita") ? 'block' : 'none' }} className='titleLeft'>In ottemperanza al General Data Protection Regulation (GDPR)</h6>
        <div style={{ display: (language === "ita") ? 'block' : 'none' }} className='policy'>
          <div className='row'>
            <div className='col'>
            Questo Sito Web raccoglie alcuni Dati Personali dei propri Utenti.<p></p>
            <b>Titolare del Trattamento dei Dati</b><p></p>
            Z.C. FASHION s.r.l. - Via dell'Artigianato, 4/B - 30020 Candiana PD<p></p>
            CF/P.IVA 05234470283 - REA: 453473<p></p>
            Rappresentante legale: Andrea Zago<p></p>
            Telefono: +39 0426 314192<p></p>
            E-mail: zetacifashion@gmail.com<p></p>
            <b>Tipologie di Dati raccolti</b><p></p>
            I Dati Personali raccolti da questo Sito Web in modo autonomo sono:<p></p>
            - E-mail<p></p>
            - Generalità<p></p>
            - Numeri di telefono.<p></p>
            <b>Modalità di trattamento</b><p></p>
            Il Titolare adotta le opportune misure di sicurezza volte ad impedire<p></p> 
            - l'accesso<p></p>
            - la divulgazione<p></p>
            - la modifica<p></p>
            non autorizzate dei Dati Personali.<p></p>
            <b>Finalità del Trattamento dei Dati raccolti</b><p></p>
            I Dati dell'Utente sono raccolti per consentire al Titolare di:<p></p>
            - Rispondere a richieste.<p></p>
            <b>Strumenti di Tracciamento</b><p></p>
            Il sito web <u>non utilizza Cookies</u> che consentano di tracciare gli Utenti.<p></p>
            Viene utilizzata la "loacalStorage" del browser unicamente per salvare la preferenza del<p></p>
            - Linguaggio usato (Italiano, Inglese o Francese)<p></p><p></p>
            - Accettazione di questa Policy.<p></p>
            <b>Ulteriori informazioni sul tempo di conservazione</b><p></p>
            I Dati Personali raccolti per scopi collegati all'esecuzione di un contratto tra il Titolare e l'Utente<p></p>
            saranno trattenuti sino a quando sia completata l'esecuzione di tale contratto nei server di Google/Gmail in formato di e-mail.
            </div>
            </div>
            <div className='row'>
            <div className='col leftPadTop'>
            <button className={`${'btn'} ${'btn-outline-success'} ${'buttonCustomWidth'}`} onClick={agree}>Accetta</button>
            <span className='leftPadTop'></span>
            <button className={`${'btn'} ${'btn-outline-danger'} ${'buttonCustomWidth'}`} onClick={disagree}>Rifiuta</button>
            </div>
            </div>
        </div>

        <h4 style={{ display: (language === "eng") ? 'block' : 'none' }} className="titleLeft">Privacy Policy of https://www.zcfashion.org/</h4>
        <h6 style={{ display: (language === "eng") ? 'block' : 'none' }} className='titleLeft'>In compliance with the General Data Protection Regulation (GDPR)</h6>
        <div style={{ display: (language === "eng") ? 'block' : 'none' }} className='policy'>
          <div className='row'>
            <div className='col'>
            This Website collects some Personal Data from its Users.<p></p>
            <b>Data Controller</b><p></p>
            Z.C. FASHION s.r.l. - Via dell'Artigianato, 4/B - 30020 Candiana PD<p></p>
            CF/P.IVA 05234470283 - REA: 453473<p></p>
            Legal representative: Andrea Zago<p></p>
            Telephone: +39 0426 314192<p></p>
            E-mail: zetacifashion@gmail.com<p></p>
            <b>Types of Data collected</b><p></p>
            The Personal Data collected by this Website independently are:<p></p>
            - E-mail<p></p>
            - General information<p></p>
            - Telephone numbers.<p></p>
            <b>Processing methods</b><p></p>
            The Owner adopts appropriate security measures to prevent<p></p>
            - access<p></p>
            - disclosure<p></p>
            - modification<p></p>
            unauthorized processing of Personal Data.<p></p>
            <b>Purposes of the Processing of Collected Data</b><p></p>
            The User's Data is collected to allow the Owner to:<p></p>
            - Respond to requests.<p></p>
            <b>Tracking Tools</b><p></p>
            The website <u>does not use Cookies</u> that allow tracking Users.<p></p>
            The browser's "loacalStorage" is used only to save the user's preference<p></p>
            - Language used (Italian, English or French)<p></p><p></p>
            - Acceptance of this Policy.<p></p>
            <b>Further information on retention time</b><p></p>
            Personal Data collected for purposes related to the performance of a contract between the Owner and the User<p></p>
            will be retained until the performance of such contract is completed on Google/Gmail servers in email format.
            </div>
            </div>
            <div className='row'>
            <div className='col leftPadTop'>
            <button className={`${'btn'} ${'btn-outline-success'} ${'buttonCustomWidth'}`} onClick={agree}>{accept}</button>
            <span className='leftPadTop'></span>
            <button className={`${'btn'} ${'btn-outline-danger'} ${'buttonCustomWidth'}`} onClick={disagree}>{decline}</button>
            </div>
            </div>
        </div>

        <h4 style={{ display: (language === "fra") ? 'block' : 'none' }} className="titleLeft">Politique de confidentialité de https://www.zcfashion.org/</h4>
        <h6 style={{ display: (language === "fra") ? 'block' : 'none' }} className='titleLeft'>Conformément à la General Data Protection Regulation (GDPR)</h6>
        <div style={{ display: (language === "fra") ? 'block' : 'none' }} className='policy'>
          <div className='row'>
            <div className='col'>
            Ce site Web collecte certaines données personnelles de ses utilisateurs.<p></p>
            <b>Responsable du traitement des données</b><p></p>
            Z.C. FASHION s.r.l. - Via dell'Artigianato, 4/B - 30020 Candiana PD<p></p>
            CF/P.IVA 05234470283 - REA: 453473<p></p>
            Représentant légal : Andrea Zago<p></p>
            Téléphone : +39 0426 314192<p></p>
            E-mail : zetacifashion@gmail.com<p></p>
            <b>Types de données collectées</b><p></p>
            Les données personnelles collectées par ce site Web de manière indépendante sont :<p></p>
            - E-mail<p></p>
            - Informations générales<p></p>
            - Numéros de téléphone.<p></p>
            <b>Méthodes de traitement</b><p></p>
            Le propriétaire adopte des mesures de sécurité appropriées pour empêcher<p></p>
            - l'accès<p></p>
            - la divulgation<p></p>
            - modification<p></p>
            traitement non autorisé des Données Personnelles.<p></p>
            <b>Finalités du Traitement des Données Collectées</b><p></p>
            Les Données de l'Utilisateur sont collectées pour permettre au Propriétaire de :<p></p>
            - Répondre aux demandes.<p></p>
            <b>Outils de Suivi</b><p></p>
            Le site Web <u>n'utilise pas de Cookies</u> permettant de suivre les Utilisateurs.<p></p>
            Le "loacalStorage" du navigateur est utilisé uniquement pour enregistrer les préférences de l'utilisateur<p></p>
            - Langue utilisée (italien, anglais ou français)<p></p><p></p>
            - Acceptation de cette Politique.<p></p>
            <b>Informations complémentaires sur la durée de conservation</b><p></p>
            Les Données Personnelles collectées à des fins liées à l'exécution d'un contrat entre le Propriétaire et l'Utilisateur<p></p>
            seront conservées jusqu'à la fin de l'exécution de ce contrat sur les serveurs Google/Gmail par courrier électronique format.
            </div>
            </div>
            <div className='row'>
            <div className='col leftPadTop'>
            <button className={`${'btn'} ${'btn-outline-success'} ${'buttonCustomWidth'}`} onClick={agree}>{accept}</button>
            <span className='leftPadTop'></span>
            <button className={`${'btn'} ${'btn-outline-danger'} ${'buttonCustomWidth'}`} onClick={disagree}>{decline}</button>
            </div>
            </div>
        </div>        
        </>
    )
}