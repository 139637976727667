import '../App.css'
import { useState,useEffect } from 'react'
import CookiesBanner from "../modules/CookiesBanner"
import { useWindowSize } from "@uidotdev/usehooks";
import { useNavigate } from 'react-router-dom'
import logo from '../images/logo.png'
import Bounce from "react-reveal/Bounce"
import GoToContacts from "../modules/GoToContacts.js"
import { Helmet } from 'react-helmet';

export default function App() {
  const [configLoaded, setConfigLoaded] = useState(false)
  const [trigger, setTrigger] = useState(false)
  const [welcome, setWelcome] = useState('')
  const [welcome2, setWelcome2] = useState('')
  const [home, setHome] = useState('')
  const [home2, setHome2] = useState('')

  const size = useWindowSize()
  let navigate = useNavigate()
  let dictionaryFile = require('../dictionary.json')
  let dictionary = JSON.stringify(dictionaryFile)
  if (dictionary !== undefined && dictionary !== 'undefined') {
      localStorage.setItem("dictionary", dictionary)
  }

  const updateStrings = () => {
    let language = localStorage.getItem("language")
    let dictionary = JSON.parse(localStorage.getItem("dictionary"))
    if (language === "ita") {
      setWelcome(dictionary.welcome.it)
      setWelcome2(dictionary.welcome2.it)
      setHome(dictionary.home.it)
      setHome2(dictionary.home2.it)
    }
    else if (language === "eng") {
      setWelcome(dictionary.welcome.en)
      setWelcome2(dictionary.welcome2.en)
      setHome(dictionary.home.en)
      setHome2(dictionary.home2.en)
    }
    else if (language === "fra") {
      setWelcome(dictionary.welcome.fr)
      setWelcome2(dictionary.welcome2.fr)
      setHome(dictionary.home.fr)
      setHome2(dictionary.home2.fr)
    }
    else
      localStorage.setItem("language", "ita")
  }

  let largeScreen = true
  if (size.width < 768)
        largeScreen = false

  const getConfiguration = async () => {

    let configFile = require('../configuration.json')
    let config = JSON.stringify(configFile)
    if (config !== undefined && config !== 'undefined') {
      localStorage.setItem('configuration', config)
      setConfigLoaded(true)
      window.settingsChanged = false
    }
  }

  useEffect(() => {
    updateStrings()
  }, [window.langchanged])

  useEffect(() => {
    updateStrings()

    let configFile = localStorage.getItem('configuration')
    if ((configFile === undefined || configFile === null) || (window.settingsChanged === true))
      getConfiguration()
    else {
      let jsonConfig = JSON.parse(configFile)
    }

    let theme =  localStorage.getItem('theme')
    if (theme === 'dark')
      document.documentElement.setAttribute('data-bs-theme','dark')
    else
      document.documentElement.setAttribute('data-bs-theme','light')

    let cookies = localStorage.getItem('cookieAccepted')
    if (cookies === '' || cookies === null || cookies === undefined)
      setTimeout(() => {
        setTrigger(true)
      }, 3000) 
  }, [])

  useEffect(() => {
    if (configLoaded === false)
      return
    
    let configFile = JSON.parse(localStorage.getItem('configuration'))
  }, [configLoaded])

  return (
    <div>
      <Helmet>
        <title>ZC Fashion</title>
        <meta name="description" content="Tessile Abbigliamento Moda Industria Produzione Vendita Fabbricazione tessuti Abbigliamento Sostenibile Made in Italy" />
      </Helmet>
      <div className='main'>
        <video src="https://storagewebservices.s3.eu-central-1.amazonaws.com/videoBG_zc.mp4" autoPlay loop muted></video>
        <div className='content'>
          <img src={logo} alt=""  className={`${largeScreen ? '' : 'pictureResize'}`} />
          <h6 className='center'>{welcome}</h6>
          <h6 className='center'>{welcome2}</h6>
        </div>
        <div className='row'>
          <div className='col container'>
            <div className="mouse"></div>
          </div>
        </div>
      </div>
      <Bounce top duration={2000}>
      <div className='row'>
        <div className='col centerHomeText'>
          <h4 className="pageContentHome ps-3">{home}</h4><br></br>
          <h4 className="pageContentHome ps-3">{home2}</h4>
        </div>
      </div>
      </Bounce>     
      <CookiesBanner trigger={trigger} setTrigger={setTrigger}></CookiesBanner>
      <GoToContacts />
    </div>
  )
}
