import '../App.css'
import { Mail } from 'react-feather'
import { Phone } from 'react-feather'
import { Globe } from 'react-feather'
import { useState,useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Popup from '../modules/Popup'
import { Helmet } from 'react-helmet';

function Contatti() {
  const [titolo_contatti, setTitolo] = useState('')
  const [user, setUser] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [formmessage, setFormMessage] = useState("")
  const [responseMessage, setResponseMessage] = useState("")
  const [trigger, setTrigger] = useState(false)
  const [type, setType] = useState('')
  const [message, setMessage] = useState("")
  const [isButtonDisabled, setButtonDisabled] = useState(false)
  const [emailwrong, setEmailwrong] = useState("")
  const [submit, setSubmit] = useState("")
  const [phName, setPhName] = useState("")
  const [phEmail, setPhEmail] = useState("")
  const [phNumber, setPhNumber] = useState("")
  const [phMessage, setPhMessage] = useState("")
  const [whereWeAre, setWhereWeAre] = useState("")
  const [submitMessageOK, setSubmitMessageOK] = useState("")
  const [submitMessageKO, setSubmitMessageKO] = useState("")
  const [contatti, setContatti] = useState('')        

  window.hideContactUs = true
  let navigate = useNavigate()
  let policyMessage = ""

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const updateStrings = () => {
    let language = localStorage.getItem("language")
    let dictionary = JSON.parse(localStorage.getItem("dictionary"))
    if (language === "ita") {
      setTitolo(dictionary.titolo_contatti.it)
      setEmailwrong(dictionary.emailwrong.it)
      setSubmit(dictionary.submit.it)
      setPhName(dictionary.phName.it)
      setPhEmail(dictionary.phEmail.it)
      setPhNumber(dictionary.phNumber.it)
      setPhMessage(dictionary.phMessage.it)
      setWhereWeAre(dictionary.dove_siamo.it)
      setSubmitMessageOK(dictionary.submitMessageOK.it)
      setSubmitMessageKO(dictionary.submitMessageKO.it)
      setContatti(dictionary.contatti.it)
      policyMessage = dictionary.cannotsend.it
    }
    else if (language === "eng") {
      setTitolo(dictionary.titolo_contatti.en)
      setEmailwrong(dictionary.emailwrong.en)
      setSubmit(dictionary.submit.en)
      setPhName(dictionary.phName.en)
      setPhEmail(dictionary.phEmail.en)
      setPhNumber(dictionary.phNumber.en)
      setPhMessage(dictionary.phMessage.en)   
      setWhereWeAre(dictionary.dove_siamo.en)
      setSubmitMessageOK(dictionary.submitMessageOK.en)
      setSubmitMessageKO(dictionary.submitMessageKO.en)
      setContatti(dictionary.contatti.en)
      policyMessage = dictionary.cannotsend.en
    }
    else if (language === "fra") {
      setTitolo(dictionary.titolo_contatti.fr)
      setEmailwrong(dictionary.emailwrong.fr)
      setSubmit(dictionary.submit.fr)
      setPhName(dictionary.phName.fr)
      setPhEmail(dictionary.phEmail.fr)
      setPhNumber(dictionary.phNumber.fr)
      setPhMessage(dictionary.phMessage.fr)   
      setWhereWeAre(dictionary.dove_siamo.fr)
      setSubmitMessageOK(dictionary.submitMessageOK.fr)
      setSubmitMessageKO(dictionary.submitMessageKO.fr)
      setContatti(dictionary.contatti.fr)
      policyMessage = dictionary.cannotsend.fr
    }

    let privacy = localStorage.getItem("privacy")
    if (privacy === undefined || privacy === null) {
      setButtonDisabled(true)
      setResponseMessage(policyMessage)
    }
    else if (privacy === "yes") {
      setButtonDisabled(false)
      setResponseMessage("")
    }    
  }

  useEffect(() => {
    updateStrings()
    window.langchanged = false
  }, [window.langchanged])

  useEffect(() => {
    updateStrings()
  }, [])

  const handleSubmitRegistration = async (e) => {
    e.preventDefault()

    if ((user === "" || user === undefined) ||
        (email === "" || email === undefined) ||
        (formmessage === "" || formmessage === undefined)) {
      setMessage("Si prega di compilare tutti i campi")
      setType('alert')
      setTrigger(true)
      return     
    }

    if (!validateEmail(email)) {
      let msg = emailwrong
      setMessage(msg)
      setType('alert')
      setTrigger(true)
      return
    }
    
    let message = formmessage
    const res = await fetch('/submit', {
      method: "POST",
      body: JSON.stringify({ user, email, phone, message }),
      headers: {
      "Content-Type": "application/json"
      }
    })
    const sts = await res.status
    if (sts === 200) {
      setResponseMessage(submitMessageOK)
      setTimeout(() => {
        setResponseMessage("")
      }, 3000) 
    }
    else {
      setResponseMessage(submitMessageKO)
      setTimeout(() => {
        setResponseMessage("")
      }, 3000) 
    }
  }

  return (
    <div>
      <Helmet>
        <title>ZC Fashion - {contatti}</title>
        <meta name="description" content="" />
      </Helmet>
      <div className='row'>
      <div className='col'>
      <img src={require('../images/logo_footer.png')} className='leftPadTop pictureResizeLogo'></img>
      <h5 className="leftPadForm">{titolo_contatti}</h5>
      <div class ='row ps-3'>
        <div className='col leftPadForm'>
          <div><Phone className='pe-1'></Phone> +39 0426 314192</div>
          <div className='ps-5'><Globe className='pe-1'></Globe>{whereWeAre}</div>
      </div>
      </div>
      <div className='leftPadForm'>
        <p className={`${isButtonDisabled === true ? 'messageWrn' :'messageInfo'}`} >
          {responseMessage}
          <span style={{ display: (isButtonDisabled === true) ? 'flex' : 'none' }} className={`${'policyButton'}`} onClick={() => navigate('/policy')}>[Privacy Policy]</span>
        </p>
      </div>
      <div className={`${'leftPadForm'}`}>
        <input className={`${'inputTextCorners'}`} value={user} placeholder={phName} name="user" type="text" onChange={(e) => setUser(e.target.value.toUpperCase())}></input>
      </div>
      <div className={`${'leftPadForm'}`}>
        <input className={`${'inputTextCorners'}`} value={email} placeholder={phEmail} name="email" autoComplete="email" type="email" onChange={(e) => setEmail(e.target.value.toLowerCase())}></input>
      </div>
      <div className={`${'leftPadForm'}`}>
        <input className={`${'inputTextCorners'}`} value={phone} placeholder={phNumber} name="phone" type="text" onChange={(e) => setPhone(e.target.value.toLowerCase())}></input>
      </div>
      <div className={`${'leftPadForm'}`}>
        <textarea className={`${'submitMessage'} ${'inputTextCorners'}`} value={formmessage} placeholder={phMessage} name="message" onChange={(e) => setFormMessage(e.target.value)}/>
      </div>
      <div className={`${'leftPadForm'}`}>
      <button className={`${'btn'} ${'btn-outline-secondary'} ${'buttonCustom'}`} onClick={handleSubmitRegistration} disabled={isButtonDisabled}>{submit}</button>
      <div className='ps-5'><Mail className='pe-1'></Mail> zetacifashion@gmail.com</div>
      </div>
      <Popup trigger={trigger} msg= {message} setTrigger={setTrigger} type={type}>
      </Popup>
      </div>
      <div className={`${'col'} ${'leftPadTop'} `}>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d391168.6757586998!2d12.228653257897792!3d45.562585022783416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477e92520af3e0f9%3A0xf0a5e6dc79c75851!2sVia%20Luigi%20Einaudi%2C%2021%2C%2030014%20Cavarzere%20VE!5e0!3m2!1sit!2sit!4v1722435315275!5m2!1sit!2sit"
        width="800" 
        height="800" 
        style={{ border: "0" }} 
        allowFullScreen="" 
        loading="lazy">
        </iframe>
      </div>      
      </div>
    </div>
  )
}

export default Contatti
