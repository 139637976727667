import '../App.css'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'

export default function GoToContacts() {
  const [contattaciButton, setContattaciButton] = useState('')
  const [contattaciFooter, setContattaciFooter] = useState('')
  
  let navigate = useNavigate()  

  const updateStrings = () => {
    let language = localStorage.getItem("language")
    let dictionary = JSON.parse(localStorage.getItem("dictionary"))
    if (language === "ita") {
      setContattaciButton(dictionary.contattaci_button.it)
      setContattaciFooter(dictionary.contattaci_footer.it)
    }
    else if (language === "eng") {
      setContattaciButton(dictionary.contattaci_button.en)
      setContattaciFooter(dictionary.contattaci_footer.en)
    }
    else if (language === "fra") {
      setContattaciButton(dictionary.contattaci_button.fr)
      setContattaciFooter(dictionary.contattaci_footer.fr)
    }
    else
      localStorage.setItem("language", "ita")
  }

  useEffect(() => {
    updateStrings()
  }, [window.langchanged])

  useEffect(() => {
    updateStrings()
  }, [])

  return (
    <>
      <div className="appFooterContactUs">
        <div className='row border-top'>
          <div className='col centerNoPad'>
          <img src={require('../images/logo_footer.png')} className='leftPadTop pictureResizeLogo'></img>
          </div>          
        </div>                 
        <div className='row'>
          <div className='col centerNoPad ps-4 pe-2'><h5>{contattaciFooter}</h5></div>          
        </div>                                 
        <div className='row pb-2'>
          <div className='col centerNoPad'>
          <button className={`${'btn'} ${'btn-secondary'} ${'buttonCustom'}`} onClick={() =>navigate("/contatti")} >{contattaciButton}</button>
          </div>
        </div>                                 
      </div>
    </>
  )
}