import React from 'react';
import { useState, useEffect } from 'react'
import styled from 'styled-components';
import { NavLink as Link} from 'react-router-dom'
import { Home } from 'react-feather'
import { Users } from 'react-feather'
import { Archive } from 'react-feather'
import { ShoppingCart } from 'react-feather'
import { useWindowSize } from "@uidotdev/usehooks";

const Nav = styled.nav`
  color: #fff;
  height:50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1A1A18;
  padding: 0.5rem calc((100vw -1000px)/2);
  z-index: 10;
`;

const NavLink = styled(Link)`
color: #FFF;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
cursor: pointer;
font-weight: bold;
&.active{
  color: orange;
}
`

const NavMenu = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    display: none;
  }
`
const NavMenuMobile = styled.div`
  align-items: center;

  @media (max-width: 768px) {
    display: flex;
  }
`
const CommonNavItem = styled.div`
    cursor: pointer;
    float: ${(props) => (props.rightmost ? 'right' : 'left')};
    display: block;
    color: inherit;
    text-align: center;
    padding: 0px 70px;
    text-decoration: none;
`

const DropDownContainer = styled.div`
    float: left;
    overflow: hidden;
`

const NavItem = styled(CommonNavItem).attrs({ as: Link })``;

// the same as NavItem but no link
const DropDownLabel = styled(CommonNavItem)``

const DropDownContent = styled.div`
    display: none;
    position: absolute;
    background-color: #1A1A18;
    min-width: 10px;
    z-index: 1;

    ${DropDownContainer}:hover & {
        display: block;
    }

    ${NavItem} {
      float: none;
      padding: 12px 16px;
      text-align: center;
  }
`

export default function Navbar () {
  const [chi_siamo, setChiSiamo] = useState('')       
  const [servizi, setServizi] = useState('')          
  const [contatti, setContatti] = useState('')        
  const [currentLanguage, setCurrentLanguage] = useState('')        

  const size = useWindowSize()

  const updateStrings = () => {
    let language = localStorage.getItem("language")
    let dictionary = JSON.parse(localStorage.getItem("dictionary"))
    if (dictionary === undefined || dictionary === null) {
      let dictionaryFile = require('../dictionary.json')
      dictionary = JSON.stringify(dictionaryFile)
      if (dictionary !== undefined && dictionary !== 'undefined') {
          localStorage.setItem("dictionary", dictionary)
      }
    }

    if (language === "eng")
    {
      setChiSiamo(dictionary.chi_siamo.en)
      setServizi(dictionary.servizi.en)
      setContatti(dictionary.contatti.en)
      setCurrentLanguage('EN')
    }
    else if (language === "fra")
    {
      setChiSiamo(dictionary.chi_siamo.fr)
      setServizi(dictionary.servizi.fr)
      setContatti(dictionary.contatti.fr)
      setCurrentLanguage('FR')
    }
    else {
      setChiSiamo(dictionary.chi_siamo.it)
      setServizi(dictionary.servizi.it)
      setContatti(dictionary.contatti.it)
      setCurrentLanguage('IT')
    }
  }

  useEffect(() => {
    updateStrings()
  }, [])

  let largeScreen = true
  if (size.width < 768)
        largeScreen = false

  const handleSetIta = () => {
    localStorage.setItem("language", "ita")
    updateStrings()
    window.langchanged = !window.langchanged
  }

  const handleSetEng = () => {
    localStorage.setItem("language", "eng")
    updateStrings()
    window.langchanged = !window.langchanged
  }

  const handleSetFra = () => {
    localStorage.setItem("language", "fra")
    updateStrings()
    window.langchanged = !window.langchanged
  }

return (
    <Nav>
      <NavMenu>
        <NavLink to="/" ><img src={require('../images/logo_topbar.png')} ></img><Home className='menuSpace' /></NavLink>
        <NavLink to="/azienda" >{chi_siamo}<Archive className='menuSpace' /></NavLink>
        <NavLink to="/servizi" >{servizi}<ShoppingCart className='menuSpace' /></NavLink>
        <NavLink to="/contatti" >{contatti}<Users className='menuSpace' /></NavLink>
      </NavMenu>
      <NavMenuMobile style={{ display: (largeScreen == false) ? 'flex' : 'none' }}>
        <NavLink to="/" ><Home /></NavLink>
        <NavLink to="/azienda" ><Archive /></NavLink>
        <NavLink to="/servizi" ><ShoppingCart /></NavLink>
        <NavLink to="/contatti" ><Users /></NavLink>
      </NavMenuMobile>
      <DropDownContainer>
          <DropDownLabel><div className='langLabel'>{currentLanguage}</div></DropDownLabel>
          <DropDownContent>
              <NavItem onClick={handleSetIta}><img src={require('../images/it.png')} ></img></NavItem>
              <NavItem onClick={handleSetEng}><img src={require('../images/uk.png')} ></img></NavItem>
              <NavItem onClick={handleSetFra}><img src={require('../images/fr.png')} ></img></NavItem>
          </DropDownContent>
      </DropDownContainer>      
    </Nav>
  )
}