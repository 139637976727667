import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
import Bounce from "react-reveal/Bounce"
import GoToContacts from "../modules/GoToContacts.js"
import { Helmet } from 'react-helmet';

function Servizi() {
  const [titolo_servizi, setTitolo] = useState('')
  const [titolo_servizi2, setTitolo2] = useState('')
  const [servizi, setServizi] = useState('')          

  let navigate = useNavigate()
  
  const updateStrings = () => {
    let language = localStorage.getItem("language")
    let dictionary = JSON.parse(localStorage.getItem("dictionary"))
    if (language === "ita") {
      setTitolo(dictionary.titolo_servizi.it)
      setTitolo2(dictionary.titolo_servizi2.it)
      setServizi(dictionary.servizi.it)
    }
    else if (language === "eng") {
      setTitolo(dictionary.titolo_servizi.en)      
      setTitolo2(dictionary.titolo_servizi2.en)
      setServizi(dictionary.servizi.en)
    }
    else if (language === "fra") {
      setTitolo(dictionary.titolo_servizi.fr)
      setTitolo2(dictionary.titolo_servizi2.fr)
      setServizi(dictionary.servizi.fr)      
    }
  }

  useEffect(() => {
    updateStrings()
    window.langchanged = false
  }, [window.langchanged])

  useEffect(() => {
    updateStrings()
  }, [])

  const images = [
    {
      original: "./pics/servizi01.jpg",
      thumbnail:"./pics/servizi01.jpg",
    },
    {
      original: "./pics/servizi02.jpg",
      thumbnail:"./pics/servizi02.jpg",
    },
    {
      original: "./pics/servizi03.jpg",
      thumbnail:"./pics/servizi03.jpg",
    },
    {
      original: "./pics/servizi04.jpg",
      thumbnail:"./pics/servizi04.jpg",
    },
    {
      original: "./pics/servizi05.jpg",
      thumbnail:"./pics/servizi05.jpg",
    },
    {
      original: "./pics/servizi13.jpg",
      thumbnail:"./pics/servizi13.jpg",
    },
    {
      original: "./pics/servizi14.jpg",
      thumbnail:"./pics/servizi14.jpg",
    },
    {
      original: "./pics/servizi15.jpg",
      thumbnail:"./pics/servizi15.jpg",
    },
  ]

  const images2 = [
    {
      original: "./pics/servizi06.jpg",
      thumbnail:"./pics/servizi06.jpg",
    },
    {
      original: "./pics/servizi07.jpg",
      thumbnail:"./pics/servizi07.jpg",
    },
    {
      original: "./pics/servizi08.jpg",
      thumbnail:"./pics/servizi08.jpg",
    },
    {
      original: "./pics/servizi09.jpg",
      thumbnail:"./pics/servizi09.jpg",
    },
    {
      original: "./pics/servizi10.jpg",
      thumbnail:"./pics/servizi10.jpg",
    },
    {
      original: "./pics/servizi11.jpg",
      thumbnail:"./pics/servizi11.jpg",
    },
    {
      original: "./pics/servizi12.jpg",
      thumbnail:"./pics/servizi12.jpg",
    },
  ]

  let largeScreen = true
  if (window.innerWidth < 1200)
      largeScreen = false

  return (
    <div>
      <Helmet>
        <title>ZC Fashion - {servizi}</title>
        <meta name="description" content="Abbigliamento Uomo/Donna/Bambino T-shirt Personalizzate Abiti Sartoriali Abbigliamento Sportivo Divise Aziendali Moda Casual su Misura Tessuti naturali Cotone Organico Lino Lana Merino Seta Poliestere Riciclato Viscosa Cashmere Pizzo Denim Eco-Pelle" />
      </Helmet>
      <div className='row'>
        <div className='col-sm-8'>
          <div className='row'>
            <div className='col'>
              <div className='pictureGallery ps-3 pe-3'>
                <ImageGallery 
                  items={images} 
                  showFullscreenButton = {false}
                  showPlayButton = {false}
                  autoPlay = {true}
                  showNav = {true}
                  showThumbnails = {false}
                  slideInterval = {4000}
                />
              </div>
            </div>        
          </div>               
        </div>
        <Bounce right duration={2000}>
        <div className='col-sm-4'>
          <div className='row'>
            <div className='col'>
              <h5 className="pageContent ps-3">{titolo_servizi}</h5>
            </div>
          </div>
        </div>
        </Bounce>
      </div>
      <div className='row'>
          <Bounce left duration={2000} delay={1500}>
            <div className='col-sm-4'>
              <div className='row'>
                <div className='col'>
                  <h5 className="pageContent ps-3">{titolo_servizi2}</h5>
                </div>
              </div>
            </div>
            </Bounce>            
            <div className='col-sm-8'>
              <div className='pictureGallery ps-3 pe-3'>
              <ImageGallery 
                items={images2} 
                showFullscreenButton = {false}
                showPlayButton = {false}
                autoPlay = {true}
                showNav = {true}
                showThumbnails = {false}
                slideInterval = {4000}
              />
              </div>
            </div>        
      </div>
      <GoToContacts />
    </div>
  )
}

export default Servizi
