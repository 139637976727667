import '../App.css'
import '../css/bootstrap.min.css'
import { useState,useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
import Bounce from "react-reveal/Bounce"
import GoToContacts from "../modules/GoToContacts.js"
import { Helmet } from 'react-helmet';

function Chisiamo() {
  const [titolo_chi_siamo, setTitolo] = useState('')
  const [certificazione, setCertificazione] = useState('')
  const [certificazioneTitle, setCertificazioneTitle] = useState('')
  const [chi_siamo, setChiSiamo] = useState('')       
  
  let navigate = useNavigate()
  
  const updateStrings = () => {
    let language = localStorage.getItem("language")
    let dictionary = JSON.parse(localStorage.getItem("dictionary"))
    if (language === "ita") {
      setTitolo(dictionary.titolo_chi_siamo.it)
      setCertificazione(dictionary.certificazione.it)
      setCertificazioneTitle(dictionary.certificazione_title.it)
      setChiSiamo(dictionary.chi_siamo.it)
    }
    else if (language === "eng") {
      setTitolo(dictionary.titolo_chi_siamo.en)
      setCertificazione(dictionary.certificazione.en)
      setCertificazioneTitle(dictionary.certificazione_title.en)
      setChiSiamo(dictionary.chi_siamo.en)
    }
    else if (language === "fra") {
      setTitolo(dictionary.titolo_chi_siamo.fr)
      setCertificazione(dictionary.certificazione.fr)
      setCertificazioneTitle(dictionary.certificazione_title.fr)
      setChiSiamo(dictionary.chi_siamo.fr)
    }
  }

  const images = [
    {
      original: "./pics/chisiamo001.jpg",
      thumbnail:"./pics/chisiamo001.jpg",
    },
    {
      original: "./pics/chisiamo002.jpg",
      thumbnail:"./pics/chisiamo002.jpg",
    },
    {
      original: "./pics/chisiamo003.jpg",
      thumbnail:"./pics/chisiamo003.jpg",
    },
    {
      original: "./pics/chisiamo01.jpg",
      thumbnail:"./pics/chisiamo01.jpg",
    },
    {
      original: "./pics/chisiamo02.jpg",
      thumbnail:"./pics/chisiamo02.jpg",
    },
    {
      original: "./pics/chisiamo03.jpg",
      thumbnail:"./pics/chisiamo03.jpg",
    },
    {
      original: "./pics/chisiamo04.jpg",
      thumbnail:"./pics/chisiamo04.jpg",
    },
    {
      original: "./pics/chisiamo05.jpg",
      thumbnail:"./pics/chisiamo05.jpg",
    },
    {
      original: "./pics/chisiamo06.jpg",
      thumbnail:"./pics/chisiamo06.jpg",
    },
    {
      original: "./pics/chisiamo07.jpg",
      thumbnail:"./pics/chisiamo07.jpg",
    },
    {
      original: "./pics/chisiamo08.jpg",
      thumbnail:"./pics/chisiamo08.jpg",
    },
  ]
  
  useEffect(() => {
    updateStrings()
    window.langchanged = false
  }, [window.langchanged])

  useEffect(() => {
    updateStrings()
  }, [])

  let largeScreen = true
  if (window.innerWidth < 1200)
      largeScreen = false

  return (
    <div>
      <Helmet>
        <title>ZC Fashion - {chi_siamo}</title>
        <meta name="description" content="Produzione Ecosostenibile Filiera Corta Certificazione GOTS (Global Organic Textile Standard) Riciclo Tessuti Moda Etica Economia Circolare Produzione a Basso Impatto Ambientale Upcycling Tessile" />
      </Helmet>
      <div className='row'>
        <div className='col-sm-8'>
          <div className='row'>
            <div className='col'>
              <div className='videoAzienda'>
              <video src="https://storagewebservices.s3.eu-central-1.amazonaws.com/azienda_zc.mp4" autoPlay loop muted></video>
              </div> 
            </div> 
          </div>
          <div className='row'>
            <div className='col'>
              <div className='pictureGallery ps-3 pe-3'>
                <ImageGallery 
                  items={images} 
                  showFullscreenButton = {false}
                  showPlayButton = {false}
                  autoPlay = {true}
                  showNav = {true}
                  showThumbnails = {false}
                  slideInterval = {4000}
                />
              </div>
            </div>
          </div>          
        </div>
        <Bounce right duration={2000}>      
        <div className='col-sm-4'>
          <div className='row'>
            <div className='col'>
              <h5 className="pageContent ps-3">{titolo_chi_siamo}</h5>
            </div>
          </div>
        </div>
        </Bounce>
      </div>
      <div className='row certifications border-top pt-4 pb-4'>
        <div className='col'>
          <h3 className="ps-3 pe-3">{certificazioneTitle}</h3>
          <h5 className="ps-3 pe-3">{certificazione}</h5>
        </div>
      </div>
      <GoToContacts />
    </div>
  )
}

export default Chisiamo
